import logo from './logo.svg';
import './App.css';
import MainPage from './components/MainPage';
import './font/css/clash-display.css'


function App() {
  return (
    <div className="App">
   <MainPage/>
    </div>
  );
}

export default App;
 